import React from 'react';
import { userRoleSelector } from '../../features/user/userSlice';

import { Box, Tooltip, Menu, MenuList, MenuItem, MenuButton, Button, useToast } from '@chakra-ui/react';
import { FaChevronDown } from "@react-icons/all-files/fa/FaChevronDown";
import { FaDrawPolygon } from "@react-icons/all-files/fa/FaDrawPolygon";
import { FaMapPin } from "@react-icons/all-files/fa/FaMapPin";
import { FaTimes } from "@react-icons/all-files/fa/FaTimes";
import { useSelector } from 'react-redux';

export const AddItem = (props) => {
  const userRole = useSelector(userRoleSelector);

  const toast = useToast();

  const addNewPrePlan = () => {
    props.handleContextMenuItemSelect({
      value: "PREPLAN_LOCATION"
    });
    toast({
      title: 'Draw Location',
      position: 'top',
      description: 'Tap or click to use the drawing tool to trace location area.',
      status: 'info',
      duration: 5000,
      isClosable: true
    });
  };

  const addNewHydrant = () => {
    props.setNewHydrant(true);
    toast({
      title: 'Place Hydrant',
      position: 'top',
      description: 'Tap or click on the map to place the hydrant location.',
      status: 'info',
      duration: 5000,
      isClosable: true
    });
  };

  const buttonProps = {
    background: "#fff",
    boxShadow: "md",
    width: "11.5rem",
    borderRadius: ".25rem",
    justifyContent: "space-between"
  };

  return (
    (userRole === "ADMIN" || userRole === "PLANNER") &&
    <Box
      height="2.5rem"
      style={{
        position: "absolute",
        top: "10px",
        right: "4rem",
        zIndex: 1
      }}
    >
      {props.isInActionMode ? (
        <Button
          onClick={props.onCancelAction}
          color="red.400"
          rightIcon={<FaTimes />}
          {...buttonProps}
        >Cancel</Button>
      ) : (props.newHydrant) ? (
        <Button
          onClick={() => {
            props.setNewHydrant(false);
          }}
          color="red.400"
          rightIcon={<FaTimes />}
          {...buttonProps}
        >Cancel</Button>
      ) : (
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<FaChevronDown />}
            {...buttonProps}
            textAlign="left"
          >
            <Tooltip
              placement="right"
              label="Add Pre-Plan or Hydrant"
            >
              Create New
            </Tooltip>
          </MenuButton>
          <MenuList minWidth="">
            <MenuItem
              key={"menu-preplan"}
              onClick={e => addNewPrePlan()}
              icon={<FaDrawPolygon />}
            >
              Pre-Plan Location
            </MenuItem>
            <MenuItem
              key={"menu-hydrant"}
              onClick={addNewHydrant}
              icon={<FaMapPin />}
            >
              Add Hydrant
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Box>
  );
};
