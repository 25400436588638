import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import * as ImageAPI from '../../api/ImageAPI';
import Image from './Image';
import { useToast, Box, HStack, Flex, Skeleton, Text, Tabs, Tab, TabList, TabPanels, TabPanel, VStack } from '@chakra-ui/react';
import { selectAllStructures, addImageToStructure, removeImageFromStructure, editStructure } from '../../features/structures/structuresSlice';
import { selectAllProperties, addImageToProperty, removeImageFromProperty, editProperty } from '../../features/properties/propertiesSlice';
import { selectAllOccupants, addImageToOccupant, removeImageFromOccupant, editOccupant } from '../../features/occupants/occupantsSlice';
import { selectAllImages, setTags, uploadImage } from '../../features/images/imagesSlice';
import { LightboxComponent as Lightbox } from '../common/LightboxComponent';
import TagForm from '../form/TagFormComponent';
import { OutlineButton, SubmitButton } from '../form/Button';
import AddPhotoIcon from '../FigmaExport/AddPhotoIcon';
import axios from 'axios';
import { Exception } from 'sass';
import FilterIcon from '../FigmaExport/FilterIcon';
import { ImageFilterForm } from '../form/ImageFilterForm';


const IMAGE_TAGS = {
  command: 'Command',
  firstIn: 'First In',
  floorPlan: 'Floor Plan',
  Aerial: 'Aerial',
};

const TAB_TYPES = {
  PROPERTY: 'property',
  STRUCTURE: 'structure',
  OCCUPANT: 'occupant',
};

const ChooseFileToUpload = () => {
  return (
    <HStack h="100%" v="5rem" gap="1rem" align="center" justify="center">
      <AddPhotoIcon />
      <VStack>
        <Text color="#2C62CB" fontSize="large" fontWeight="600" >Choose file to upload</Text>
        <Text color="#707A86" fontSize="sm" fontWeight="400" >or drag and drop them here</Text>
      </VStack>
    </HStack>
  );
};

const UploadNewImage = () => {
  return (
    <VStack align="center" justify="center" h="100%" >
      <AddPhotoIcon />
      <Text color="#2C62CB" fontFamily="Inter" fontSize="12px" fontWeight="400" letterSpacing="0.4px" >Upload new image</Text>
    </VStack>
  );
};


export const ImageStrip = (props) => {
  const { onAnnotation, canEditImage, changeCounter } = props;
  
  const [filterByTag, setFilterByTag] = useState(null);

  const dispatch = useDispatch();
  const toast = useToast();

  const { customerId } = useSelector(v => v.customer);
  const { selectedStructureId } = useSelector(v => v.structures);
  const properties = useSelector(selectAllProperties);
  const structures = useSelector(selectAllStructures);
  const occupants = useSelector(selectAllOccupants);
  const allImages = useSelector(selectAllImages);

  const [uploading, setUploading] = useState(false);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);

  
  const mappedImages = useMemo(() => {
    if (!allImages || !allImages.length) return {};
    const newMappedImages = {};
    allImages.forEach(image => {
      newMappedImages[image.id] = image;
    });
    return newMappedImages;
  }, [allImages]);

  useEffect(() => {
    if (!selectedStructureId) {
      setTabs([]);
      return;
    }
    const structure = structures.find(s => s.id === selectedStructureId);
    if (!structure) {
      setTabs([]);
      return;
    }
    const property = properties.find(p => p.id === structure.propertyId);
    const structuresFiltered = structure.propertyId ? structures.filter(s => s.propertyId === structure.propertyId) : [structure];
    const occupantsFiltered = occupants.filter(o => o.structureId === selectedStructureId);
    let newTabs = property ? [{ type: TAB_TYPES.PROPERTY, label: property.name || "Property", id: property.id, customerId: property.customerId, imageIds: property.images }] : [];
    newTabs = [
      ...newTabs,
      ...structuresFiltered.map(s => ({ type: TAB_TYPES.STRUCTURE, label: s.name || "Location", id: s.id, customerId: s.customerId, imageIds: s.images })),
      ...occupantsFiltered.map(o => ({ type: TAB_TYPES.OCCUPANT, label: o.name || "Occupant", id: o.id, customerId: o.customerId, imageIds: o.images })),
    ];
    setTabs(newTabs);
  }, [selectedStructureId, properties, structures, occupants]);

  useEffect(() => {
    setSelectedTabIndex(0);
  }, [selectedStructureId]);

  useEffect(() => {
    const newImages = [];
    if (tabs && tabs[selectedTabIndex] && tabs[selectedTabIndex].imageIds && mappedImages) {
      tabs[selectedTabIndex].imageIds.forEach(imageId => {
        const img = mappedImages[imageId];
        if (!img) return;
        if (
          (!filterByTag) ||
          (filterByTag === 'notTagged' && !img.tags?.length) ||
          (img.tags && img.tags.indexOf(IMAGE_TAGS[filterByTag]) > -1)
        ) {
          newImages.push(mappedImages[imageId]);
        }
      });
    }
    setCurrentImages(newImages);
  }, [selectedTabIndex, tabs, mappedImages, filterByTag]);

  const uploadImages = async (files) => {
    setUploading(true);
    const totalIndex = files.length;
    let successfulUploads = 0;
    let failedUploads = 0;

    props.setModal({
      heading: 'Image Uploading',
      body: (
        <div>
          <span> Image upload in process... 0 / {totalIndex} </span>
        </div>
      ),
      footer: <span />
    });

    for (const file of files) {
      try {
        const uploadResult = await dispatch(uploadImage(file));
        if (uploadImage.fulfilled.match(uploadResult)) {
          const image= uploadResult.payload;
          if (tabs[selectedTabIndex].type === TAB_TYPES.PROPERTY) {
            const result = await dispatch(addImageToProperty({
              propertyId: tabs[selectedTabIndex].id,
              imageId: image.id
            }));
            if (!addImageToProperty.fulfilled.match(result)) {
              throw Exception("Upload Failed");
            }
          } else if (tabs[selectedTabIndex].type === TAB_TYPES.STRUCTURE) {
            const result = await dispatch(addImageToStructure({
              structureId: tabs[selectedTabIndex].id,
              imageId: image.id
            }));
            if (!addImageToStructure.fulfilled.match(result)) {
              throw Exception("Upload Failed");
            }
          } else if (tabs[selectedTabIndex].type === TAB_TYPES.OCCUPANT) {
            const result = await dispatch(addImageToOccupant({
              occupantId: tabs[selectedTabIndex].id,
              imageId: image.id
            }));
            if (!addImageToOccupant.fulfilled.match(result)) {
              throw Exception("Upload Failed");
            }
          }
          await axios.get(image.href);
          successfulUploads++;
          props.setModal({
            heading: 'Image Uploading',
            body: (
              <div>
                <span> Image upload in process... {successfulUploads + failedUploads} / {totalIndex} </span>
              </div>
            ),
            footer: <span />
          });
        } else {
          throw Exception("Upload Failed");
        }
      } catch (err) {
        failedUploads++;
        props.setModal({
          heading: 'Image Uploading',
          body: (
            <div>
              <span> Error encountered during image upload... {successfulUploads + failedUploads} / {totalIndex} </span>
            </div>
          ),
          footer: <span />
        });
      }
    }

    if (!failedUploads) {
      toast({
        title: 'Upload Complete',
        position: 'top',
        description: `${successfulUploads} images successfully uploaded.`,
        status: 'success',
        duration: 9000,
        isClosable: true
      });
    } else {
      toast({
        title: 'Upload Error',
        position: 'top',
        description: `There was a problem uploading the images. ${failedUploads} images failed to upload`,
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }

    setUploading(false);
    props.toggleModal();
  };

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      await uploadImages(acceptedFiles);
    }

    rejectedFiles.forEach(reject => {
      toast({
        title: 'Upload Error',
        position: 'top',
        description: `File "${reject.file.name}" was rejected (must be an image or PDF)`,
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    });
  };

  const saveTags = async (tags, index) => {
    const imageId = currentImages[index].id;
    const result = await dispatch(setTags({ imageId, tags }));
    if (setTags.fulfilled.match(result)) {
      props.toggleModal();
    }
  };

  const tagImage = index => {
    const imageId = currentImages[index].id;
    const imageName = currentImages[index].title;
    ImageAPI.getTags(imageId).then((res, err) => {
      if (err) console.warn(err);
      let tags = [];
      if (res.data === '') {
        tags = [];
      } else if (res.data) {
        tags = [...res.data];
      }
      const tagsRefined = [];
      for (let ii = 0; ii < tags.length; ii++) {
        const tag = { id: tags[ii], text: tags[ii] };
        tagsRefined.push(tag);
      }

      props.setModal({
        heading: 'Tag File (Image & PDF)',
        body: (
          <TagForm
            onConfirm={(savedTags) => saveTags(savedTags, index)}
            onDecline={props.toggleModal}
            fileName={imageName}
            tags={tagsRefined}
          />
        )
      });
    });
  };

  useEffect(() => {
    setFilterByTag(null);
  }, [selectedTabIndex, selectedStructureId]);

  const tagsData = useMemo(() => {
    if (tabs && tabs[selectedTabIndex] && tabs[selectedTabIndex].imageIds && mappedImages) {
      return [
        {
          label: 'All',
          value: 'All',
          count: tabs[selectedTabIndex].imageIds.length,
        },
        ...Object.keys(IMAGE_TAGS).map(TAG => ({
          label: IMAGE_TAGS[TAG],
          value: TAG,
          count: tabs[selectedTabIndex].imageIds.filter(imageId => {
            const img = mappedImages[imageId];
            return img && (img.tags && img.tags.indexOf(IMAGE_TAGS[TAG]) > -1);
          }).length,
        })),
        {
          label: 'Not tagged',
          value: 'notTagged',
          count: tabs[selectedTabIndex].imageIds.filter(imageId => {
            const img = mappedImages[imageId];
            return img && (!img.tags?.length);
          }).length,
        },
      ];
    }
    return [];
  }, [selectedTabIndex, tabs, mappedImages]);

  const onFilterApply = (tag) => {
    if (tag === 'All') {
      setFilterByTag(null);
    } else {
      setFilterByTag(tag);
    }
    props.toggleModal();
  };

  const openFilterModal = () => {
    props.setModal({
      heading: 'Filter Images',
      body: (
        <ImageFilterForm
          tags={tagsData}
          defaultValue={filterByTag || 'All'}
          onApply={onFilterApply}
          onCancel={props.toggleModal}
        />
      )
    });
  };

  const moveImage = async (index, direction) => {
    if (currentImages.length <= 1) {
      return;
    }
    // To Use on request failure.
    const originalImages = [...currentImages ? currentImages : []];
    const dir = direction === 'left' ? index - 1 : index + 1;
    const newImages = [...currentImages];
    const leftImage = currentImages[dir];
    newImages[dir] = currentImages[index];
    newImages[index] = leftImage;

    setCurrentImages(newImages);
    const imageIds = newImages.map(image => image.id);
    const editData = [
      {
        op: 'replace',
        path: '/images',
        value: imageIds
      },
    ];
    let failed = false;
    if (tabs[selectedTabIndex].type === TAB_TYPES.PROPERTY) {
      const result = await dispatch(editProperty({ propertyId: tabs[selectedTabIndex].id, propertyPatch: editData }));
      if (!editProperty.fulfilled.match(result)) {
        failed = true;
      }
    } else if (tabs[selectedTabIndex].type === TAB_TYPES.STRUCTURE) {
      const result = await dispatch(editStructure({ structureId: tabs[selectedTabIndex].id, structurePatch: editData }));
      if (!editStructure.fulfilled.match(result)) {
        failed = true;
      }
    } else if (tabs[selectedTabIndex].type === TAB_TYPES.OCCUPANT) {
      const result = await dispatch(editOccupant({ occupantId: tabs[selectedTabIndex].id, occupantPatch: editData }));
      if (!editOccupant.fulfilled.match(result)) {
        failed = true;
      }
    }

    if (failed) {
      console.error('ImageStrip::moveImage');
      // Revert to original order.
      setCurrentImages(originalImages);
      toast({
        title: 'Image Reorder Failed',
        position: 'top',
        description: 'Image order was not updated on the server. Please try again.',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
    }
  };

  const toggleLightbox = () => {
    setLightboxIsOpen(!lightboxIsOpen);
  };

  const onSelection = index => {
    setCurrentImageIndex(index);
    toggleLightbox();
  };

  const confirmImageDelete = index => {
    const deleteImage = async (index) => {
      let success = false;
      if (tabs[selectedTabIndex].type === TAB_TYPES.PROPERTY) {
        const result = await dispatch(removeImageFromProperty({
          propertyId: tabs[selectedTabIndex].id,
          imageId: currentImages[index].id
        }));
        if (removeImageFromProperty.fulfilled.match(result)) {
          success = true;
        }
      } else if (tabs[selectedTabIndex].type === TAB_TYPES.STRUCTURE) {
        const result = await dispatch(removeImageFromStructure({
          structureId: tabs[selectedTabIndex].id,
          imageId: currentImages[index].id
        }));
        if (removeImageFromStructure.fulfilled.match(result)) {
          success = true;
        }
      } else if (tabs[selectedTabIndex].type === TAB_TYPES.OCCUPANT) {
        const result = await dispatch(removeImageFromOccupant({
          occupantId: tabs[selectedTabIndex].id,
          imageId: currentImages[index].id
        }));
        if (removeImageFromOccupant.fulfilled.match(result)) {
          success = true;
        }
      }

      if (success) {
        toast({
          title: 'Success',
          position: 'top',
          description: 'Image deleted',
          status: 'success',
          duration: 9000,
          isClosable: true
        });
        props.toggleModal();
      } else {
        toast({
          title: 'Error',
          position: 'top',
          description: 'Unable to delete image',
          status: 'error',
          duration: 9000,
          isClosable: true
        });
      }
    };

    props.setModal({
      heading: 'Delete an Image',
      body: (<Text>Are you sure you want to delete this image?</Text>),
      footer: (<HStack justifyContent="end" spacing="1rem">
        <OutlineButton onClick={props.toggleModal} width="8rem">No</OutlineButton>
        <SubmitButton onClick={() => deleteImage(index)} width="8rem">Yes</SubmitButton>
      </HStack>)
    });
  };

  const renderLightbox = () => {
    if (!lightboxIsOpen) return null;
    const lightboxProps = {
      images: currentImages,
      lightboxIsOpen: lightboxIsOpen,
      toggleLightbox: toggleLightbox,
      startingImageIndex: currentImageIndex
    };
    return <Lightbox {...lightboxProps} />;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: true,
    multiple: true,
    accept: {
      'image/*': [],
      'application/pdf': ['.pdf']
    }
  });

  return (
    <React.Fragment>
      {selectedStructureId && allImages && (
        <Box position="absolute" bottom="0" width="100%" bg="#FFFFFF" paddingY="12px" paddingX="8px" borderRadius="8px" borderWidth="1px" >
          <Tabs
            index={selectedTabIndex}
            onChange={setSelectedTabIndex}
            variant="soft-rounded"
            colorScheme={tabs && tabs[selectedTabIndex] && (tabs[selectedTabIndex].type === TAB_TYPES.PROPERTY ? 'red' : tabs[selectedTabIndex].type === TAB_TYPES.OCCUPANT ? 'green' : 'blue')}
          >
            <TabList flexWrap="wrap" paddingBottom="16px" >
              {tabs.map((tab, index) => (
                <Tab key={index} fontFamily="Inter" fontWeight="700" fontSize="12px" lineHeight="18px" letterSpacing="0.15px" >{tab.label}</Tab>
              ))}
              <OutlineButton
                ml="auto"
                fontSize="sm"
                color="#2C62CB"
                borderColor="#2C62CB"
                leftIcon={<FilterIcon fill="#2C62CB" />}
                onClick={() => openFilterModal()}
                isDisabled={!tabs?.[selectedTabIndex]?.imageIds?.length}
              >
                {filterByTag ? `Filter: ${filterByTag === 'notTagged' ? 'Not Tagged' : IMAGE_TAGS[filterByTag]}` : 'Filter images'}
              </OutlineButton>
            </TabList>
            <TabPanels>
              {tabs.map((tab, index) => (
                <TabPanel key={index} bg="#FAFAFA" rounded="lg" padding="6px">
                  <Flex gap="10px" fontSize="0" margin="auto" height="200px" whiteSpace="nowrap" overflowX="auto" overflowY="hidden" {...getRootProps()}>
                    {!uploading && !filterByTag && tab.imageIds && tab.imageIds.length && (!currentImages || !currentImages.length) && (
                      <Fragment>
                        {[...Array(tab.imageIds.length)].map((e, i) => (
                          <Skeleton key={`skeleton-${i}-image`} index={`imageskelt-${i}`} minWidth="256px" height="200px" />
                        ))}
                      </Fragment>
                    )}
                    {(uploading) ? (
                      <img
                        src="/images/flow-loader.svg"
                        style={{ height: '150px', margin: '10px auto', borderRadius: "8px", display: "block" }}
                        alt=""
                      />
                    ) : null}
                    {!uploading && ((tab.customerId !== customerId) || !canEditImage) && (!currentImages || (currentImages && currentImages.length < 1)) && (
                      <div style={{ height: "100%" }}>
                        <img src="/images/no-image-available.jpg" alt="" style={{ display: "block", height: "100%", margin: "0 auto", borderRadius: "8px" }} />
                      </div>
                    )}
                    {currentImages && !uploading
                      ? currentImages.map((image, index) => (
                        <Image
                          src={(image.hrefThumbnail === 'NoImage') ? '/images/pdf-thumbnail.png' : (`${image.hrefThumbnail}?${changeCounter}`)}
                          key={`${image.id}-${index}-image`}
                          extremeleft={index === 0}
                          extremeright={index === (currentImages.length - 1)}
                          onImageClick={() => onSelection(index)}
                          onAnnotationClick={onAnnotation(image)}
                          onTaggingClick={() => tagImage(index)}
                          onDeleteClick={() => confirmImageDelete(index)}
                          onLeftClick={async () => moveImage(index, 'left')}
                          onRightClick={async () => moveImage(index, 'right')}
                          pdfFileName={(image.hrefThumbnail === 'NoImage') ? image.title : ' '}
                          canEditImage={canEditImage && (image.hrefThumbnail !== 'NoImage')}
                          isPDF={(image.hrefThumbnail === 'NoImage')}
                          isMine={tab.customerId === customerId}
                          supportsTouch={supportsTouch}
                        />
                      )) : null}
                      {!uploading && (tab.customerId === customerId) && canEditImage && currentImages && currentImages.length ? (
                        <Box width="256px" position="relative" height="100%" display="inline-block" verticalAlign="top" bg="#FFFFFF" borderRadius="8px">
                          <label htmlFor="upload" style={{ cursor: "pointer", width: "256px", height: "100%", display: "inline-block" }}>
                            <UploadNewImage />
                          </label>
                          <input id="upload" style={{ display: 'none' }}
                            {...getInputProps()}
                          />
                        </Box>
                      ) : null}
                      {!uploading && (tab.customerId === customerId) && canEditImage && (!currentImages?.length) ? (
                        <Box height="100%" width="100%" >
                          <label htmlFor="upload" style={{ cursor: "pointer", height: "100%", width: "100%", display: "inline-block" }}>
                            <ChooseFileToUpload />
                          </label>
                          <input id="upload" style={{ display: 'none' }}
                            {...getInputProps()}
                          />
                        </Box>
                      ) : null}
                  </Flex>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
          {renderLightbox()}
        </Box>
      )}</React.Fragment>);
};

export default ImageStrip;
